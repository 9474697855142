import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"
import _imports_0 from '@/images/Strype-logo-name-64-2x.png'


const _hoisted_1 = { class: "vflex" }
const _hoisted_2 = { class: "hflex hflex-centered" }
const _hoisted_3 = { id: "footer" }
const _hoisted_4 = { id: "contactTable" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "vflex vflex-centered" }
const _hoisted_7 = {
  href: "https://www.kcl.ac.uk/",
  target: "_blank"
}
const _hoisted_8 = ["width"]
const _hoisted_9 = { id: "strypeHistoryLinkTD" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, {
          to: "/",
          class: "navbar-brand"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "Strype",
              height: "64"
            }, null, -1)
          ])),
          _: 1
        }),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "news-banner" }, null, -1))
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass({'flex-grow':true, 'coloured-dynamic-section': _ctx.isFeedbackRelatedRoute()})
    }, [
      _createVNode(_component_router_view)
    ], 2),
    _createElementVNode("footer", _hoisted_3, [
      _createElementVNode("table", null, [
        _createElementVNode("tbody", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("td", null, [
              _cache[8] || (_cache[8] = _createElementVNode("h4", null, " Contact ", -1)),
              _createElementVNode("table", _hoisted_4, [
                _createElementVNode("tbody", null, [
                  _cache[6] || (_cache[6] = _createElementVNode("tr", null, [
                    _createElementVNode("td", null, [
                      _createElementVNode("i", { class: "fa fa-map-marker-alt" })
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("span", null, [
                        _createTextVNode("Department of Informatics"),
                        _createElementVNode("br"),
                        _createTextVNode("Bush House, 30 Aldwych"),
                        _createElementVNode("br"),
                        _createTextVNode("London"),
                        _createElementVNode("br"),
                        _createTextVNode("WC2B 4BG")
                      ])
                    ])
                  ], -1)),
                  _cache[7] || (_cache[7] = _createElementVNode("tr", null, [
                    _createElementVNode("td", null, [
                      _createElementVNode("i", { class: "fa fa-envelope" })
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("span", null, [
                        _createElementVNode("a", { href: "mailto:team@strype.org" }, "team@strype.org")
                      ])
                    ])
                  ], -1)),
                  _createElementVNode("tr", null, [
                    _cache[3] || (_cache[3] = _createElementVNode("td", null, [
                      _createElementVNode("i", { class: "fas fa-user-secret" })
                    ], -1)),
                    _createElementVNode("td", null, [
                      _createVNode(_component_router_link, { to: _ctx.policyRoute }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createElementVNode("span", null, "Privacy policy", -1)
                        ])),
                        _: 1
                      }, 8, ["to"])
                    ])
                  ]),
                  (false && !_ctx.isFeedbackRelatedRoute())
                    ? (_openBlock(), _createElementBlock("tr", _hoisted_5, [
                        _createElementVNode("td", null, [
                          _createVNode(_component_router_link, { to: _ctx.feedbackRoute }, {
                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                              _createElementVNode("i", { class: "fas fa-comment-dots" }, null, -1)
                            ])),
                            _: 1
                          }, 8, ["to"])
                        ]),
                        _createElementVNode("td", null, [
                          _createVNode(_component_router_link, { to: _ctx.feedbackRoute }, {
                            default: _withCtx(() => _cache[5] || (_cache[5] = [
                              _createElementVNode("span", null, "Feedback", -1)
                            ])),
                            _: 1
                          }, 8, ["to"])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _createElementVNode("td", null, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("a", _hoisted_7, [
                    _createElementVNode("img", {
                      src: "/images/kcl-logo.svg",
                      width: (_ctx.isScreenPortrait) ? 100 : 200,
                      alt: "King's College London"
                    }, null, 8, _hoisted_8)
                  ]),
                  _createElementVNode("span", null, [
                    _cache[9] || (_cache[9] = _createTextVNode("© ")),
                    _createElementVNode("span", null, _toDisplayString(_ctx.displayedYear), 1),
                    _cache[10] || (_cache[10] = _createTextVNode(" K-PET Group - King's College London"))
                  ])
                ])
              ])
            ]),
            _createElementVNode("td", _hoisted_9, [
              (!_ctx.$route.path.startsWith(_ctx.strypeHistoryRoute))
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: _ctx.strypeHistoryRoute
                  }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createElementVNode("span", null, "Strype release history", -1)
                    ])),
                    _: 1
                  }, 8, ["to"]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ])
  ]))
}